<template>
  <div v-show="secondTypes&&secondTypes.length">
    <div class="flex">
      <Select
        transfer
        class="item-category"
        v-model="second"
        v-if="secondTypes&&secondTypes.length"
        @on-change="secondCodeChange"
        placeholder="请选择二级分类"
        :disabled="disabled"
      >
        <Option v-for="item in secondTypes" :value="item.value" :key="item.text">{{ item.text }}</Option>
      </Select>
      <Select
        transfer
        class="item-category category-type"
        v-model="third"
        @on-change="thirdCodeChange"
        v-if="!hiddenThirdCategory&&thirdTypes&&thirdTypes.length"
        placeholder="请选择三级分类"
        :disabled="disabled"
      >
        <Option v-for="item in thirdTypes" :value="item.value" :key="item.text">{{ item.text }}</Option>
      </Select>
    </div>
  </div>
</template>

<script>
import commonService from './commonService';
export default {
  props: {
    firstCode: {
      required: true
    },
    secondCode: {
      default: ''
    },
    thirdCode: {
      default: ''
    },
    hiddenThirdCategory: {
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    attachAllOpt: {
      default: false
    }
  },
  data() {
    return {
      secondTypes: null, //二级分类列表
      thirdTypes: null,
      second: '',
      third: ''
    };
  },
  methods: {
    secondCodeChange(value, isInit = false) {
      if (!isInit) {
        if (this.secondCode !== value) {
          this.$emit('update:secondCode', value);
        }
        if (this.thirdCode !== '') {
          this.$emit('update:thirdCode', '');
        }
      }
      if (!value) {
        this.thirdTypes = null;
        if (this.thirdCode !== '') {
          this.$emit('update:thirdCode', '');
        }
        return;
      }
      commonService.getThirdType({ firstCode: this.firstCode, secondCode: value }).then((res) => {
        this.thirdTypes = res;
        if (this.attachAllOpt && this.thirdTypes) {
          this.thirdTypes.unshift({ text: '全部', title: '', value: '' });
        }
        if (isInit) {
          this.third = this.thirdCode;
        }
        this.$forceUpdate();
      });
    },
    thirdCodeChange(value) {
      if (this.thirdCode !== value) {
        this.$emit('update:thirdCode', value);
      }
    }
  },
  beforeMount() {},
  watch: {
    firstCode: {
      handler() {
        if (!this.firstCode) {
          return;
        }
        commonService.getCategoryByContentType(this.firstCode).then((res) => {
          this.secondTypes = res;
          if (this.attachAllOpt && this.secondTypes && this.secondTypes.length) {
            this.secondTypes.unshift({ text: '全部', title: '', value: '' });
          }
          if (res.some((ele) => ele.value === this.secondCode)) {
            this.second = this.secondCode;
            this.secondCodeChange(this.secondCode, true);
          } else {
            this.second = '';
            this.secondCodeChange('', false);
          }
        });
      },
      immediate: true
    },
    secondCode: {
      handler() {
        if (this.second !== this.secondCode) {
          this.second = this.secondCode;
          this.secondCodeChange(this.second, true);
        }
      },
      immediate: true
    },
    thirdCode: {
      handler() {
        if (this.third !== this.thirdCode) {
          this.third = this.thirdCode;
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.category-type {
  margin-left: 6px;
}
.item-category {
  flex: 0;
}
</style>