import axios from '../api/newAxiosProxy'

// 当年龄类型，二三级分类 等后台改了之后，一定要记得跟新版本号！否则将使用缓存而无法更新：
const CommonData = {}
// 
const putCache = (key, value) => {
    CommonData[key] = JSON.parse(JSON.stringify(value || 'null'))
    
}
const getCache = (key) => {
    if (!CommonData[key]) {
        return undefined
    }
    if ('null' === CommonData[key]) {
        return null
    }
    return JSON.parse(JSON.stringify(CommonData[key]))
}



const AGE_TYPE = 'a-t='
export default {
    getAgeType() {
        const cache = getCache(AGE_TYPE)
        if (undefined !== cache) {
            return Promise.resolve(cache)
        }
        return axios({
            method: 'get',
            url: '/common/content/ageType',
        }, true).then(res => {
            putCache(AGE_TYPE, res)
            return res
        })
    },
    getCategoryByContentType(contentType) {
        const cache = getCache(contentType)
        if (undefined !== cache) {
            return Promise.resolve(cache)
        }
        return axios({
            method: 'get',
            url: "/common/content/getCategoryByContentType",
            params: { contentType }
        }, true).then(res => {
            putCache(contentType, res)
            return res
        })

    },
    /**
     * 
     * @param {{firstCode, secondCode}} params firstCode即contentType，secondCode即二级分类
     */
    getThirdType(params) {
        const key = params.firstCode + '*' + params.secondCode
        const cache = getCache(key)
        if (undefined !== cache) {
            return Promise.resolve(cache)
        }
        return axios({
            method: 'get',
            url: "/common/content/getThirdType",
            params
        }, true).then(res => {
            putCache(key, res)
            return res
        })
    }
}